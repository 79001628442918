/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from 'react'

import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { gql } from '@apollo/client'
import { withStyles, AppBar, Tabs, CircularProgress, Badge, Button, Dialog, Card, DialogTitle, DialogContent, Tab, Typography } from '@material-ui/core'
import ValidatedIcon from '@material-ui/icons/CheckCircle'
import DoneIcon from '@material-ui/icons/Done';

import EditionForm from '../../../../components/BPMTable/TableComponents/Mutation/MutationSetup'
import { customFormConfirmStrategy, customFormCancelStrategy } from '../../../../components/BPMTable/TableMapperCreator'
import { downloadFileFromUrl } from '../../../../components/FileDownloader/FileDownloader'

import formCreatorCreatorFactoryCreator from '../../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import AccountFormCreator from '../../../../tableDefinitions/Inventory/Account/AccountFormCreator'

import { thousandSeparator } from '../../../../utils/tools'
import * as ConceptMutations from './mutations/ConceptMutation'
import * as DestinationMutations from './mutations/DestinationMutation'
import * as ChargeGroupMutation from './mutations/ChargeGroupMutation'
import AssociationComponent from './AssociationComponent'
import FileIcon from './FileIcon'
import ServiceNumberSetup from './ServiceNumberSetup'
import TabPanel from './TabPanel'
import { AxiosContext } from '../../../../contexts/Axios'

const styles = theme => ({
  heading: {
    fontSize: 15
  },
  secondaryHeading: {
    fontSize: 15
  },
  iconValidated: {
    color: '#3e50b5',
  },
  iconNotValidated: {
    color: 'red',
  },
  column: {
    flexBasis: '33.33%',
  },
  columnDouble: {
    flexBasis: '66.66%',
  },
  tittle: {
    display: "flex",
    justifyContent: "space-between",
  },
  subTittle: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 30,
  },
  details: {
    padding: "0px 10px",
    justifyContent: "center",
  },
  dialogContent: {
    backgroundColor: "#fafafa",
    borderRadius: 5,
    width: "100%",
  },
  filterInput: {
    color: "black",
  },
  tableBodyScroll: {
    display: 'block',
    overflow: 'auto',
  },
  tableHeadBlock: {
    display: "block",
    width: "100%",
    paddingRight: 15,
  },
  tableHeadCell: {
    width: "45%",
    padding: "0 25px",
    backgroundColor: "white",
    borderBottom: "1px solid #e0e0e0",
  },
  tableCell: {
    width: "45%",
    padding: "5px",
    wordBreak: "break-all",
    display: "flex",
    alignItems: "center",
  },
  column1: {
    gridColumn: 1,
    fontWeight: 'bold',
  },
  column2: {
    gridColumn: 2,
    marginLeft: 10,
  },
  column3: {
    gridColumn: 3,
    margin: 10,
  },
});


class InvoiceContent extends React.Component {
  static contextType = AxiosContext

  constructor(props) {
    super(props)
    this.state = {
      serviceNumbers: this.props.data.rawData.unassociatedServiceLinesByInvoice,
      expanded: '',
      tabValue: this.getInitialTabValue(),
      isLoadingResult: false,
      isLoadingDriver: false,
    }
  }

  createMessage = (text, variant) => {
    this.props.TableObject.addApplicationSnackMessage({
      text: text,
      variant: variant,
    })
  }

  updateServiceNumbersCount = count => {
    this.setState({ serviceNumbers: count })
    if (count === 0) {
      this.accountSubmitStrategy(null)
      //this.setState({tabValue: this.getInitialTabValue()})
    }
  }

  handleDownloadSBO = (client, item, id, extension) => {
    const err = () => this.createMessage(this.props.t("Error Downloading"), "error")
    const nullFunc = () => null;
    downloadFileFromUrl(client, `/billing/download/${item}/`, id, nullFunc, err)
  };

  handleValidate = () => {
    const ids = [this.props.data.rawData.invoice.id];
    const err = () => this.createMessage(this.props.t("Error Validating"), "error")
    const success = () => this.createMessage(this.props.t("Validated"), "success")
    this.props.client.mutate({
      mutation: gql`
      mutation($ids : [String]){
        validateInvoices(ids:$ids){
          invoice{
            id
          }
        }
      }`,
      variables: { ids }
    }).then(res => { success(); this.props.refetch() })
      .catch(er => err())
  };

  accountSubmitStrategy = (tabData) => {
    this.props.refetch().then(() => {
      this.setState({ tabValue: this.getInitialTabValue() })
      //this.handleAutoTab(tabData)
    })
  };

  getInitialTabValue = () => {
    if (this.props.data.rawData.unassignedChargeDescriptions.length > 0) {
      return 1
    }

    if (this.props.data.rawData.unassignedDestinationDescriptions.length > 0) {
      return 2
    }

    if (this.props.data.rawData.unassignedConcepts.length > 0) {
      return 3
    }

    if (this.props.data.accountValidation.props.value != "true") {
      return 4
    }

    if (this.props.data.rawData.unassociatedServiceLinesByInvoice != 0) {
      return 5
    }

    return 0;
  };

  closeTab = () => {
    this.setState({ tabValue: this.getInitialTabValue() });
    return null
  };

  handleChange = newValue => {
    this.setState(state => (
      { tabValue: state.tabValue === newValue ? this.getInitialTabValue() : newValue }
    ));
  };

  handleBack = () => {
    this.props.history.goBack();
  }

  handleAutoTab = (tabData) => {
    const tabs = tabData;
    const tabsWithIndex = tabs.map((data, index) => ({ index: index, ...data }))
    const available_tabs = tabsWithIndex.filter(tab => !tab.disabled);
    const higherTabs = available_tabs.filter(tab => tab.index >= this.state.tabValue);
    const lowerTabs = available_tabs.filter(tab => tab.index < this.state.tabValue);

    var nextTab;
    if (higherTabs.length) {
      nextTab = higherTabs[0].index;
    } else {
      nextTab = lowerTabs[lowerTabs.length - 1].index;
    }
    this.handleChange(nextTab);
  }

  setBadge = (tittle, validated, errValue) => {
    return (
      validated ?
        <Badge style={{ padding: "0 15px" }}
          badgeContent={
            <ValidatedIcon className={this.props.classes.iconValidated} />
          }>
          {tittle}
        </Badge>
        :
        <Badge color="secondary"
          style={{ padding: "0 15px" }}
          badgeContent={errValue}>
          {tittle}
        </Badge>
    )
  }

  render() {
    const { data, classes, refetch, t } = this.props;
    const { tabValue, serviceNumbers } = this.state;
    const id = this.props.data.rawData.invoice.id;
    const accountId = data.rawData.invoice.account.id;

    const unassignedChargeDescriptions = data['rawData']['unassignedChargeDescriptions'];
    const unassignedDestinationDescriptions = data['rawData']['unassignedDestinationDescriptions'];
    const unassignedConcepts = data['rawData']['unassignedConcepts'];

    const availableConcepts = data['rawData']['availableConcepts'].map(c => ({ name: c.technology.name + " - " + c.name, id: c.id })) || [];
    const availableChargeGroups = data['rawData']['availableChargeGroups'] || [];
    const availableDestinations = data['rawData']['availableDestinations'] || [];

    const chargeDescriptionsState = unassignedChargeDescriptions.length == 0;
    const destinationDescriptionsState = unassignedDestinationDescriptions.length == 0;
    const conceptsState = unassignedConcepts.length == 0;

    const accountValidation = data.accountValidation.props.value == "true";


    let tabData = [
      {
        label: t("Summary"),
        disabled: false,
      },
      {
        label: this.setBadge(t("Concepts"), chargeDescriptionsState, unassignedChargeDescriptions.length),
        disabled: chargeDescriptionsState,
      },
      {
        label: this.setBadge(t("Destinations"), destinationDescriptionsState, unassignedDestinationDescriptions.length),
        disabled: destinationDescriptionsState,
      },
      {
        label: this.setBadge(t("Charge Groups"), conceptsState, unassignedConcepts.length),
        disabled: conceptsState,
      },
      {
        label: this.setBadge(t("Account"), accountValidation, "!"),
        disabled: Boolean(accountValidation),
      },
      {
        label: this.setBadge(t("Inventory"), serviceNumbers == 0, serviceNumbers),
        disabled: serviceNumbers == 0,
      },
    ]

    const accountFormCreatorCreator = formCreatorCreatorFactoryCreator.getFormCreatorCreatorFromIdentifier(AccountFormCreator.getId(), null)

    const accountFormCreator = accountFormCreatorCreator.getFormCreator()
    accountFormCreator.setOnConfirmFormStrategy(new customFormConfirmStrategy(() => this.accountSubmitStrategy(tabData)));
    accountFormCreator.setOnCancelFormStrategy(new customFormCancelStrategy(this.closeTab));
    const accountManager = accountFormCreator.create();

    return (
      <div>
        <DialogTitle id="scroll-dialog-title" style={{ fontSize: 15, paddingBottom: 0 }}>
          <div className={classes.tittle}>
            <Typography variant="h6"> <b>{t("Invoice")} n°:</b>{` ${data["identifier"].props.value}`} </Typography>
            <div className={classes.subTittle}>
              <Button variant="contained" size="small" style={{ margin: "5px 0" }} onClick={this.handleBack} >
                {t('Back')}
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: "10px 10px 15px" }} >
          <AppBar position="sticky" color="default" style={{ marginBottom: 10 }}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              ref={this.tabsRef}
              variant="fullWidth"
              scrollButtons="auto"
            >
              {
                tabData.map(
                  (data, index) =>
                    <Tab
                      key={"tab_" + index}
                      label={data.label}
                      disabled={data.disabled}
                      index={index}
                      onClick={() => this.handleChange(index)}
                    />
                )
              }
            </Tabs>
          </AppBar>
          <TabPanel key={0} value={tabValue} index={0}>
            <DetailsPannel
              key={"details_00"} 
              state={data.state.props.value}
              isLoadingResult={this.state.isLoadingResult}
              t={t}
              invoiceData={this.props.data.rawData.invoice}
              totalCost={data.totalCost.props.value}
              classes={classes}
              resultFileDownloader={(item, id, extension) => this.handleDownloadSBO(this.context.axiosClient, item, id, extension)}
              canBeValidated={data.canBeValidated.props.value === 'true' && data.state.props.value != 'VALIDATED'}
              initialTabValue={this.getInitialTabValue()}
              handleValidate={this.handleValidate}
              currency={data.currency.props.value}
              date={data.date.props.value}
            />
          </TabPanel>
          <TabPanel key={1} value={tabValue} index={1} validated={unassignedChargeDescriptions.length == 0}>
            <AssociationComponent
              unassignedFields={unassignedChargeDescriptions}
              unassignedName={t("Charge Description")}
              targetName={"Concept"}
              invoiceId={id}
              mutation={ConceptMutations.ChargeDescriptionsAssignation}
              QUERY={ConceptMutations.ConceptCreationQuery}
              FORM={ConceptMutations.ConceptCreationForm}
              refetch={refetch}
              handleChange={() => this.handleAutoTab(tabData)}
              tabValue={tabValue}
              cancel={this.closeTab}
              classes={classes}
              availableFields={availableConcepts}
              client={this.props.client}
            />
          </TabPanel>
          <TabPanel key={2} value={tabValue} index={2} validated={unassignedDestinationDescriptions.length == 0}>
            <AssociationComponent
              unassignedFields={unassignedDestinationDescriptions}
              unassignedName={t("Destination Description")}
              targetName={"Destination"}
              mutation={DestinationMutations.DestinationDescriptionsAssignation}
              invoiceId={id}
              QUERY={DestinationMutations.nullQuery}
              FORM={DestinationMutations.DestinationCreationForm}
              refetch={refetch}
              handleChange={() => this.handleAutoTab(tabData)}
              tabValue={tabValue}
              cancel={this.closeTab}
              classes={classes}
              availableFields={availableDestinations}
              client={this.props.client}
            />
          </TabPanel>
          <TabPanel key={3} value={tabValue} index={3} validated={unassignedConcepts.length == 0}>
            <AssociationComponent
              unassignedFields={unassignedConcepts}
              unassignedName={t("Concept")}
              targetName={"Charge Group"}
              invoiceId={id}
              mutation={ChargeGroupMutation.ConceptsAssignation}
              QUERY={ChargeGroupMutation.ChargeGroupCreationQuery}
              FORM={ChargeGroupMutation.ChargeGroupCreationForm}
              refetch={refetch}
              handleChange={() => this.handleAutoTab(tabData)}
              tabValue={tabValue}
              cancel={this.closeTab}
              classes={classes}
              availableFields={availableChargeGroups}
              client={this.props.client}
            />
          </TabPanel>
          <TabPanel key={4} value={tabValue} index={4} validated={accountValidation}>
            <EditionForm
              TableObject={accountManager}
              mutate={'edit'}
              extraVariables={{ id: accountId }}
              client={this.props.client}
              classes={classes}
            />
          </TabPanel>
          <TabPanel key={5} value={tabValue} index={5}>
            <ServiceNumberSetup
              cancel={this.closeTab}
              handleAutoTab={() => this.handleAutoTab(tabData)}
              date={data.date.props.value}
              invoiceId={id}
              classes={classes}
              client={this.props.client}
              refetch={refetch}
              updateCount={this.updateServiceNumbersCount}
              changeTab={() => this.handleAutoTab(tabData)}
              serviceNumbers={serviceNumbers}
            />
          </TabPanel>
        </DialogContent>
      </div>
    );
  }
}

const DetailsPannel = (props) => {
  const { state, invoiceData, totalCost, currency, date, resultFileDownloader, classes, t, initialTabValue, canBeValidated, handleValidate, isLoadingResult } = props;
  return (
    <Card key={"card_detail_01"} style={{ width: "100%", padding: 10 }} >
      <div key={"dp_item_01"} style={{ display: "flex", justifyContent: "space-between" }}>
        <div key={"dp_item_0101"} style={{ padding: 15, display: "grid" }}>
          <Typography key={"col1_cost"} variant="subtitle1" className={classes.column1}>{t("Total Cost")} :</Typography>
          <Typography key={"col2_cost"} variant="subtitle1" className={classes.column2}>{` ${thousandSeparator(Number(totalCost), 2)}`}</Typography>
          <Typography key={"col1_ccy"} variant="subtitle1" className={classes.column1}>{t("Currency")} :</Typography>
          <Typography key={"col2_ccy"} variant="subtitle1" className={classes.column2}>{` ${currency}`}</Typography>
          <Typography key={"col1_state"} variant="subtitle1" className={classes.column1}>{t("State")} :</Typography>
          <Typography key={"col2_state"} variant="subtitle1" className={classes.column2}> {` ${state}`} </Typography>
          <Typography key={"col1_date"} variant="subtitle1" className={classes.column1}>{t("Date")} :</Typography>
          <Typography key={"col2_date"} variant="subtitle1" className={classes.column2}>{` ${date || " - "}`}</Typography>
          {invoiceData.billingRequest.billingrequestfileSet.map((item, idx) => <>
            <Typography key={`col1_${idx}`} variant="subtitle1" className={classes.column1}>{(`${item.driverFile.name} (${item.driverFile.extension.toUpperCase()}) :`)}</Typography>
            <Typography key={`col2_${idx}`} variant="subtitle1" className={classes.column2}>
              <FileIcon key={`fileIcn_${idx}`} extension={item.driverFile.extension} onClick={() => resultFileDownloader("request", item.id, item.driverFile.extension)} />
            </Typography>
          </>)}
          <Typography key={"col1_out"} variant="subtitle1" className={classes.column1}>{t("Standard billing output")} :</Typography>
          <Typography key={"col2_out"} variant="subtitle1" className={classes.column2}>
            <FileIcon onClick={() => resultFileDownloader("result", invoiceData.id, "xlsx")} extension="xlsx" />
          </Typography>
          <Dialog
            key={'dialog_01'}
            open={isLoadingResult}
            maxWidth='lg'
            scroll="paper"
            id={'Loading'}>
            <div style={{ width: 150, height: 150, textAlign: "center", padding: 50 }}>
              <CircularProgress />
            </div>
          </Dialog>
        </div>
      </div>
      <div key={"dp_item_02"} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button disabled={!canBeValidated} variant="contained" size="small" style={{ margin: 10 }} onClick={handleValidate} >
          <DoneIcon />
          {t("Validate")}
        </Button>
      </div>
    </Card>
  )
}

export default withTranslation('invoiceValidation')(withStyles(styles)(withRouter(InvoiceContent)))